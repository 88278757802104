<template>
	<div class="container">
		<!-- <div class="dx-header">
			<div class="width-1300 item">
				<div class="left">
					<router-link :to="'/index'">江西耐普</router-link>
				</div>
				<div class="right">
					<router-link :to="'/user'" v-if="username">{{ username }}</router-link>
					<div class="colse-btn" @click="signOut" v-if="username"><span>退出登录</span></div>
					<router-link :to="'/'" v-else>登录</router-link>
				</div>
			</div>
		</div> -->
		<div class="logo">
			<div class="width-1300">
				<router-link :to="'/index'">
					<img src="../../static/images/logo4.png" alt="">
				</router-link>
				<div class="user-info">
					<div class="user-info-item" @mouseenter="enter(1)" @mouseleave="leave(1)" v-if="route_list.list1">
						<router-link to="/design">产品智能辅助设计</router-link>
						<i class="iconfont iconshangjiantou"
							:style="{transform: height1 == 0 ? 'rotate(180deg)' : 'rotate(0deg)'}"></i>
						<div class="list" :style="{height:height1 + 'px'}">
							<router-link to="/designinfo" v-if="route_list.list1_1">
								<div>磨机衬板辅助设计</div>
							</router-link>
							<router-link to="/designinfo" v-if="route_list.list1_2">
								<div>渣浆泵选型设计</div>
							</router-link>
							<router-link to="/../monitor8505/" v-if="route_list.list1_3">
								<div>旋流器辅助设计</div>
							</router-link>
							<router-link to="/designinfo" v-if="route_list.list1_4">
								<div>筛分设备辅助设计</div>
							</router-link>
						</div>
					</div>
					<div class="user-info-item" @mouseenter="enter(2)" @mouseleave="leave(2)" v-if="route_list.list2">
						<router-link to="/testing">产品运行在线监测</router-link>
						<i class="iconfont iconshangjiantou"
							:style="{transform: height2 == 0 ? 'rotate(180deg)' : 'rotate(0deg)'}"></i>
						<div class="list" :style="{height:height2 + 'px'}">
							<router-link to="/operation" v-if="route_list.list2_1">
								<div>产品运行信息汇集</div>
							</router-link>
							<router-link to="/monitor" v-if="route_list.list2_2">
								<div>产品磨损在线监测</div>
							</router-link>
						</div>
					</div>
					<div class="user-info-item" @mouseenter="enter(3)" @mouseleave="leave(3)" v-if="route_list.list3">
						<router-link to="/datalake">全球产品大数据湖</router-link>
						<i class="iconfont iconshangjiantou"
							:style="{transform: height3 == 0 ? 'rotate(180deg)' : 'rotate(0deg)'}"></i>
						<div class="list" :style="{height:height3 + 'px'}">
							<router-link to="/standard" v-if="route_list.list3_1">
								<div>标准化目录&文献&专利</div>
							</router-link>
							<router-link to="/supplier" v-if="route_list.list3_2">
								<div>全球竞争对手调研</div>
							</router-link>
							<router-link to="/case" v-if="route_list.list3_3">
								<div>产品设计经典案例图书馆</div>
							</router-link>
						</div>
					</div>
					<div class="user-info-item" @mouseenter="enter(4)" @mouseleave="leave(4)">
						<!-- <div class="colse-btn" @click="signOut" v-if="username"><span>退出登录</span></div> -->
						<span v-if="userinfo.username">{{ userinfo.username }}</span>
						<router-link :to="'/'" v-else>登录</router-link>
						<i class="iconfont iconshangjiantou"
							:style="{transform: height4 == 0 ? 'rotate(180deg)' : 'rotate(0deg)'}" v-if="userinfo.username"></i>
						<div class="list" :style="{height:height4 + 'px'}">
							<div @click="$router.push('/user')">个人中心</div>
							<div @click="signOut">退出登录</div>
						</div>
					</div>
				</div>
				<div class="logo-more" :class="isShow ? 'active' : ''" @click="show">
					<div class="logo-more-list logo-more-list1"></div>
					<div class="logo-more-list logo-more-list2"></div>
					<div class="logo-more-list logo-more-list3"></div>
					<div class="logo-more-zhansdoh" ref="zhansdoh" :style="{display:isShow ? 'block' : 'none'}">
						<div class="logo-more-zhansdoh-name" v-if="userinfo.username">
							<router-link to="/user" class="user_header">
								<img :src="$store.state.imaUrl + userinfo.avatarUrl" >
							</router-link>
							<router-link to="/user">{{userinfo.username}}</router-link>
						</div>
						<div class="logo-more-zhansdoh-login" v-else>
							<router-link :to="'/'">点击登录</router-link>
						</div>
						
						<div class="logo-more-zhansdoh-item" v-if="route_list.list1">
							<router-link :to="'/design'">产品智能辅助设计</router-link>
							<div class="list" v-if="route_list.list1_1">
								<router-link :to="'/designinfo'">磨机衬板辅助设计</router-link>
							</div>
							<div class="list" v-if="route_list.list1_2">
								<router-link :to="'/designinfo'">渣浆泵选型设计</router-link>
							</div>
							<div class="list" v-if="route_list.list1_3">
								<router-link :to="'/designinfo'">旋流器辅助设计</router-link>
							</div>
							<div class="list" v-if="route_list.list1_4">
								<router-link :to="'/designinfo'">筛分设备辅助设计</router-link>
							</div>
						</div>
						<div class="logo-more-zhansdoh-item" v-if="route_list.list2">
							<router-link :to="'/testing'">产品运行在线监测</router-link>
							<div class="list" v-if="route_list.list2_1">
								<router-link :to="'/operation'">产品运行信息汇集</router-link>
							</div>
							<div class="list" v-if="route_list.list2_2">
								<router-link :to="'/monitor'">产品磨损在线监测</router-link>
							</div>
						</div>
						<div class="logo-more-zhansdoh-item" v-if="route_list.list3">
							<router-link :to="'/datalake'">全球产品大数据湖</router-link>
							<div class="list" v-if="route_list.list3_1">
								<router-link :to="'/standard'">标准化目录&文献&专利</router-link>
							</div>
							<div class="list" v-if="route_list.list3_2">
								<router-link :to="'/supplier'">全球竞争对手调研</router-link>
							</div>
							<div class="list" v-if="route_list.list3_3">
								<router-link :to="'/case'">产品设计经典案例图书馆</router-link>
							</div>
							<div class="list"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Cookie from 'js-cookie';
	export default {
		data() {
			return {
				isShow: false,
				userinfo: '',
				height1: 0,
				height2: 0,
				height3: 0,
				height4: 0,
				route_list:{
					list1:false,
					list1_1:false,
					list1_2:true,
					list1_3:true,
					list1_4:true,
					list2:false,
					list2_1:false,
					list2_2:false,
					list3:false,
					list3_1:false,
					list3_2:false,
					list3_3:false,
				},
			}
		},
		created() {
			// if(document.documentElement.clientWidth < 768){
			// 	this.isM = true;
			// }
		},
		mounted() {
			if (Cookie.get("userinfo")) {
				this.userinfo = JSON.parse(Cookie.get("userinfo"));
			}
			this.routePd();
		},
		methods: {
			show() {
				// console.log(document.getElementsByClassName('logo-more-zhansdoh'));
				this.isShow = !this.isShow;
				if (this.isShow) {
					document.getElementsByClassName('logo-more-zhansdoh')[0].classList.remove('logo-more-active');
					setTimeout(() => {
						document.getElementsByClassName('logo-more-zhansdoh')[0].classList.add('logo-more-active');
					}, 200)
				} else {
					document.getElementsByClassName('logo-more-zhansdoh')[0].classList.remove('logo-more-active');
				}
			},
			signOut() {
				this.$notify({
					title: "提示",
					message: "操作成功！",
					type: "success",
				});
				Cookie.remove("frontRemembertoken");
				Cookie.remove("userinfo");
				this.username = '';
				this.$router.push("/");
			},
			enter(index) {
				if (index == 1) {
					this.height1 = 175;
				} else if (index == 2) {
					this.height2 = 94;
				} else if (index == 3) {
					this.height3 = 135;
				} else if (index == 4 && this.userinfo.username) {
					this.height4 = 94;
				}
			},
			leave(index) {
				if (index == 1) {
					this.height1 = 0;
				} else if (index == 2) {
					this.height2 = 0;
				} else if (index == 3) {
					this.height3 = 0;
				} else if (index == 4 && this.userinfo.username) {
					this.height4 = 0;
				}
			},
			routePd(){
				let route = JSON.parse(localStorage.getItem("route"));
				if(route == null){
					return;
				}
				for (let i = 0; i < route.length; i++) {
					if(route[i].id == 84){
						this.route_list.list1 = true;
						for (let j = 0; j < route[i].children.length; j++) {
							if(route[i].children[j].id == 87){
								this.route_list.list1_1 = true;
							}
						}
					}
					if(route[i].id == 85){
						this.route_list.list2 = true;
						for (let j = 0; j < route[i].children.length; j++) {
							if(route[i].children[j].id == 91){
								this.route_list.list2_1 = true;
							}
							if(route[i].children[j].id == 92){
								this.route_list.list2_2 = true;
							}
						}
					}
					if(route[i].id == 86){
						this.route_list.list3 = true;
						for (let j = 0; j < route[i].children.length; j++) {
							if(route[i].children[j].id == 93){
								this.route_list.list3_1 = true;
							}
							if(route[i].children[j].id == 94){
								this.route_list.list3_2 = true;
							}
							if(route[i].children[j].id == 95){
								this.route_list.list3_3 = true;
							}
						}
					}
				}
			}
		}
	}
</script>

<style lang="scss">
	.logo {
		position: fixed;
		z-index: 999;
		top: 0;
		left: 0;
		right: 0;
		color: #fff;
		font-size: 16px;
		padding: 10px 0;
		// background-color: rgba($color: #000, $alpha: .5);
		background-color: #00337D;

		&>div {
			display: flex;
			justify-content: space-between;

			&>a {
				display: inline-block;
				vertical-align: middle;

				@media screen and (min-width:320px) and (max-width:767px) {
					margin-top: 6px;
				}

				img {
					width: 230px;
					margin-top: 4px;
					@media screen and (min-width:320px) and (max-width:767px) {
						margin-top: 2px;
						width: 200px;
					}
				}
			}
		}

		.user-info {
			width: 65%;
			display: inline-block;
			vertical-align: middle;
			text-align: right;
			@media screen and (min-width:320px) and (max-width:767px) {
				display: none;
			}

			.user-info-item {
				display: inline-block;
				vertical-align: middle;
				position: relative;
				width: 23.5%;
				height: 40px;
				padding-top: 10px;
				box-sizing: border-box;
				text-align: center;
				cursor: pointer;
				margin-right: 2%;
				transition: all .3s;

				&:last-child {
					margin-right: 0;
				}

				.list {
					background-color: #fff;
					font-size: 14px;
					box-sizing: border-box;
					position: absolute;
					padding: 0 30px;
					left: 0;
					right: 0;
					margin-top: 18px;
					text-align: left;
					overflow: hidden;
					color: #000;
					transition: height .3s;

					a {
						color: #000;
					}

					&::after {
						content: '';
						height: 3px;
						background-color: #000;
						position: absolute;
						left: 0;
						right: 0;
						top: 0px;
					}

					div {
						margin: 20px 0;
						position: relative;

						&::after {
							content: '';
							width: 5px;
							height: 5px;
							border-radius: 50%;
							border: 1px solid #e5e5e5;
							position: absolute;
							left: -15px;
							top: 7px;
						}

						&:hover {
							&::after {
								border-color: #215fb0;
								background-color: #215fb0;
							}
						}
					}
				}

				.iconfont {
					display: inline-block;
					margin-left: 15px;
					transition: all .5s;
				}
			}

			a {
				color: #fff;
				text-decoration: none;
			}
		}

		.logo-more {
			border-radius: 50%;
			height: 40px;
			width: 40px;
			line-height: 50px;
			text-align: center;
			display: none;
			position: relative;
			border: 1px solid #0064C8;
			transition: all 1s;

			&::before {
				content: "";
				display: block;
				width: 1px;
				height: 1px;
				border-radius: 100%;
				background-color: #000;
				position: absolute;
				top: 50%;
				left: 50%;
				opacity: 0;
				transform: translate(-50%, -50%);
				transition: all 1.5s;
				z-index: -1;
			}

			@media screen and (min-width:320px) and (max-width:767px) {
				display: block;
			}

			&-list {
				display: inline-block;
				width: 1px;
				background-color: #0064C8;
				height: 24px;
				position: relative;
				transition: all 1s;

				&::before {
					display: block;
					content: "";
					height: 5px;
					width: 5px;
					border-radius: 100%;
					border: 1px solid #fff;
					background-color: #0064C8;
					position: absolute;
					left: -3px;
					top: 0;
					transition: all 1s;
				}
			}

			&-zhansdoh {
				position: fixed;
				width: 100%;
				left: 0px;
				top: 0px;
				z-index: -1;
				height: 100%;
				background-color: #2A2A2A;
				opacity: 0;
				cursor: default;
				padding-top: 80px;
				transition: all 1s;

				a {
					color: #000;
					text-decoration: none;
				}
				&-name{
					a{
						color: #fff;
					}
					.user_header{
						width: 80px;
						height: 80px;
						margin: 0px auto;
						overflow: hidden;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						img{
							height: 100%;
						}
					}
				}
				&-login {
					margin: 0 auto;
					width: 100px;
					height: 100px;
					line-height: 100px;
					color: #000;
					font-size: 14px;
					border-radius: 50%;
					background-color: #fff;
					text-align: center;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
					}
				}

				&-item {
					font-size: 14px;
					border-top: 1px solid #707070;
					overflow: hidden;
					padding: 10px 0;
					line-height: 30px;
					& > a {
						font-weight: 600;
						display: block;
					}
					a {
						color: #fff;
						text-decoration: none;
					}

					.list {
						padding: 0 15px;
						box-sizing: border-box;
						display: inline-block;
						width: 50%;
						line-height: 20px;
					}
				}
			}

			&-active {
				opacity: 1;
			}

			&-list1 {
				&::before {
					animation: gogo1 2s infinite linear;
					top: 100%;
					margin-top: -5px;
				}
			}

			&-list2 {
				margin: 0 7px;

				&::before {
					animation: gogo2 2s infinite linear;
					top: 50%;
					margin-top: -5px;
				}
			}

			&-list3 {
				&::before {
					animation: gogo3 2s infinite linear;
					top: 0;
					margin-top: -5px;
				}
			}
		}

		.active {
			border-color: #fff;

			&::before {
				width: 10000px;
				height: 10000px;
				transition: all 2s;
				-webkit-transition: all 2s;
				z-index: -99;
				opacity: 1;
			}

			.logo-more-list {
				transition: all 1s;

				&::before {
					opacity: 0;
				}
			}

			.logo-more-list1 {
				position: relative;
				top: 2px;
				left: 8px;
				background-color: #fff;
				transform: rotate(42deg);
			}

			.logo-more-list2 {
				opacity: 0;
			}

			.logo-more-list3 {
				background-color: #fff;
				position: relative;
				top: 2px;
				right: 8px;
				transform: rotate(-45deg);
			}
		}
	}

	.dx-header {
		position: fixed;
		z-index: 999;
		top: 0;
		left: 0;
		right: 0;
		color: #fff;
		background-color: rgba(4, 29, 109, 0.77);
		font-size: 16px;

		@media screen and (min-width:320px) and (max-width:767px) {
			display: none;
		}

		.item {
			display: flex;
			justify-content: space-between;

			.left {
				height: 50px;
				line-height: 50px;

				a {
					// background-color: #0064C8;
					color: #fff;
					border: 0;
					cursor: pointer;
					text-decoration: none;
				}
			}

			.right {
				height: 50px;
				line-height: 50px;

				.colse-btn {
					display: inline-block;
					font-size: 14px;
					margin-left: 15px;
					cursor: pointer;
				}

				a {
					// background-color: #0064C8;
					color: #fff;
					border: 0;
					font-size: 14px;
					cursor: pointer;
					text-decoration: none;

					&:last-child {
						margin-left: 15px;
					}
				}
			}
		}
	}

	@keyframes gogo1 {
		0% {
			top: 100%;
		}

		25% {
			top: 50%;
		}

		50% {
			top: 0%;
		}

		75% {
			top: 50%;
		}

		100% {
			top: 100%;
		}
	}

	@keyframes gogo2 {
		0% {
			top: 50%;
		}

		30% {
			top: 100%;
		}

		75% {
			top: 0%
		}

		100% {
			top: 50%
		}
	}

	@keyframes gogo3 {
		0% {
			top: 0%;
		}

		25% {
			top: 50%;
		}

		50% {
			top: 100%;
		}

		75% {
			top: 50%;
		}

		100% {
			top: 0%;
		}
	}
</style>
