<template>
	<div class="container">
		<div class="footer">
			<div class="width-1300">
				<!-- <div class="login-logo">
					<router-link :to="'/index'">
						<img src="../../static/images/logo3.png" alt="">
					</router-link>
				</div> -->
				<div class="info">
					<a href="https://beian.miit.gov.cn/">版权© 2003-2023 授权所有</a>
					<span>江西耐普矿机股份有限公司</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		created() {

		},
		methods: {
			toUrl(url) {
				window.open(url);
			}
		}
	}
</script>

<style lang="scss">
	.footer {
		background-color: #00337D;
		color: #fff;
		font-size: 13px;
		padding: 15px 0;

		a {
			color: #fff;
			text-decoration: none;
			transition: color .3s;

			// &:hover {
			// 	color: #0064C8;
			// }
		}

		p {
			margin: 0 0;
			margin-bottom: 5px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.login-logo {
			width: 800px;
			// background-color: #fff;
			text-align: left;
			padding: 0 0 20px;
			box-sizing: border-box;

			img {
				width: 170px;
				max-height: 100%;
			}

			.shares {
				display: inline-block;
				color: #ececec;
				font-size: 12px;
				position: relative;
				margin-left: 10px;

				&::after {
					content: '';
					width: 1px;
					position: absolute;
					bottom: 3px;
					top: 3px;
					background-color: #ececec;
				}

				p {
					margin-left: 10px;

					&:last-child {
						letter-spacing: 1px;
					}
				}
			}

			@media screen and(min-width:320px) and (max-width:767px) {
				width: 350px;
			}

			@media screen and(min-width:320px) and (max-width:360px) {
				width: 300px;
			}
		}

		.info {
			text-align: center;
			a{
				margin-left: 15px;
				@media screen and(min-width:320px) and (max-width:767px) {
					margin-left: 0;
				}
			}
			span {
				margin-left: 200px;
				@media screen and(min-width:320px) and (max-width:767px) {
					margin-left: 0;
					display: block;
					margin-top: 10px;
				}
			}
		}
	}
</style>
