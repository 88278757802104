<template>
	<div class="container">
		<top-header></top-header>
		<div class="operationinfo">
			<!-- 头部导航 -->
			<div class="width-1300">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/testing' }">产品运行在线监测</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/operation' }">产品运行情况</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ name: 'operationtree', query:{ id:this.$route.query.id }}">{{name}}</el-breadcrumb-item>
          <el-breadcrumb-item ><span >{{pname}}</span>
          </el-breadcrumb-item>
				</el-breadcrumb>
				<div class="top">
					<div class="title"><span v-if="this.$route.query.type == 1">工况</span>
           </div>
					<div class="search">
						<input type="" name="" id="" value="" placeholder="请输入关键字搜索" v-model="searchValue"/>
						<div class="btn" @click="getTableinfo()">搜索</div>
					</div>
				</div>
				<div class="right-info">
					<div class="mine-table">
						<el-table :data="tableData.data" style="width: 100%" stripe
							:header-cell-style="{'background-color': '#fafafa'}">
							<el-table-column prop="id" label="序号" width="180" align="center">
							</el-table-column>
							<el-table-column prop="name" label="目录/文件名" width="180" align="center">
                <template slot-scope="scope">
                <el-image :id="scope.row.show_image_id" style="height: 22px" v-if="scope.row.file_type == 'png' ||  scope.row.file_type == 'jpg'
                              || scope.row.file_type == 'jpeg' || scope.row.file_type == 'gif'"  :src="scope.row.url" :preview-src-list="srcList">
										</el-image>
                  <span v-else>{{scope.row.name}}</span>
                  </template>
							</el-table-column>
							<el-table-column prop="size" label="大小/MB" width="180" align="center">
							</el-table-column>
							<el-table-column prop="created_at" label="上传日期" width="180" align="center">
							</el-table-column>
							<el-table-column label="操作" align="center">
								<template slot-scope="scope">
									<el-button size="mini" type="text" v-if="scope.row.type_id == 1" @click="handleIn(scope.$index, scope.row)">
										进入</el-button>
                  <el-button size="mini" type="text" v-if="scope.row.type_id == 0" @click="handleEdit(scope.$index, scope.row)">
										预览</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="bottom-page">
						<el-pagination layout="prev, pager, next" small :total="tableData.total" :page-size="10" :pager-count="5"
							@current-change="handleCurrentChange" background>
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
		},
		data() {
			return {
				value: '',
				options: [],
				active_name: 0,
				page: 1,
				searchValue:'',
        name:'',
        pname:'',
				tableData: [],
        srcList:[]
			}
		},
		created() {
      this.getInfo();
      this.getPidInfo();
      this.getTableinfo();
		},
		methods: {
      getInfo(){
        this.$get('front/mine/get_details', {
					id: this.$route.query.id
				}).then(res => {
					 this.name = res.data.data.name;
				})
      },
      getPidInfo(){
        let pid = this.$route.query.pid;
        if(pid == 0){
           pid = this.$route.query.id
           this.pname = '全部';
         }else{
           this.$get('front/mine_file/get_details', {
             id: pid
          }).then(res => {
             this.pname = res.data.data.name;
          })
         }

      },
			handleCurrentChange(val) {
				this.page = val;
			},
			gotoId(Anchor) {
				this.active_name = Anchor;
				this.getTableinfo();

			},
			getTableinfo(){
				this.$get('front/mine_file/get_list',{
					page:this.page,
					num:10,
					name:this.searchValue,
					pid:this.$route.query.pid,
					mine_id:this.$route.query.id
				}).then(res =>{
          for(var i in res.data.data.data){
             if(res.data.data.data[i]['type_id'] == 1){
               res.data.data.data[i]['size'] = '--';
             }else{
               res.data.data.data[i]['url'] = 'https://api.naipu.cloud/'+res.data.data.data[i]['url'];
               res.data.data.data[i]['show_image_id'] = 'show_image_'+res.data.data.data[i]['id'];
               if(res.data.data.data[i]['file_type'] == 'png' || res.data.data.data[i]['file_type'] == 'jpg'
                   || res.data.data.data[i]['file_type'] == 'jpeg' || res.data.data.data[i]['file_type'] == 'gif'){

                 this.srcList.push(res.data.data.data[i]['url']);
               }
             }
           }
          this.tableData = res.data.data;
				})

			},
      handleIn(index, row) {
        this.$router.push({
					query: {
            id:row.mine_id,
            pid: row.id
					},
					name: 'operationinfo'
				});
        location.reload();
      },
			handleEdit(index, row) {
				//console.log(index, row);
				// if(document.documentElement.clientWidth < 768){
				// 	alert('手机暂不支持预览！');
				// }else{
					// window.open(`http://localhost:8081/#/preview?type=operation&mine_id=${this.$route.query.id}&id=${row.id}`)
					//window.open(`#/preview?type=operation&mine_id=${this.$route.query.id}&id=${row.id}`);  //线上目录
          if(row.file_type == 'png' || row.file_type == 'jpg' || row.file_type == 'jpeg' || row.file_type == 'gif'){
              document.getElementById('show_image_'+row.id).click()
          }
          else if(row.file_type == 'doc' || row.file_type == 'docx' || row.file_type == 'xls' || row.file_type == 'xlsx'){
            window.open(`https://view.officeapps.live.com/op/view.aspx?src=${row.url}`);  //线上目录
          }
          else if(row.file_type == 'pdf'){
            window.open(`${row.url}`);  //线上目录
        }

			},
		},

  }
</script>

<style lang="scss">
	.bottom-page {
		text-align: center;
		margin: 30px 0;
	}

	.image {
		margin-bottom: 40px;
		text-align: center;

		iframe {
			margin-top: 20px;
			// border: 0;
			width: 70%;
			height: 500px;

			@media screen and (min-width:320px) and (max-width:767px) {
				width: 100%;
			}
		}
	}

	.workshop {
		margin-bottom: 40px;

		.title {
			text-align: center;

			h4 {
				color: #666;
			}

			p {
				color: #666;
				font-size: 14px;
				margin-top: 15px;
			}
		}

		.test {
			padding-left: 20px;
			margin-top: 10px;
			padding-bottom: 15px;
			border-bottom: 1px solid #2252AA;
			color: #666666;

			img {
				width: 100%;
			}
		}
	}

	.reserves {
		margin-bottom: 40px;

		.title {
			text-align: center;

			h4 {
				color: #666;
			}
		}

		.table {
			margin-top: 40px;

			@media screen and (min-width:320px) and (max-width:767px) {
				font-size: 13px;
			}

			.tr {
				border-bottom: 1px solid #dcdcdc;
				font-size: 0;

				@media screen and (min-width:320px) and (max-width:767px) {
					display: -webkit-box;
				}

				&:nth-child(2n) {
					background-color: #F2F3F3;
				}

				.th {
					display: inline-block;
					padding: 10px 0;
					vertical-align: top;
					border-right: 1px solid #dcdcdc;
					font-weight: 800;
					text-align: center;
					font-size: 16px;
					background-color: #fff;
					color: #215FB0;

					@media screen and (min-width:320px) and (max-width:767px) {
						display: table-cell;
					}

					&:nth-child(1) {
						width: 14.95%;
					}

					&:nth-child(2),
					&:nth-child(3) {
						width: 22.93%;
					}

					&:nth-child(4) {
						width: 14.9%;
					}

					&:nth-child(5) {
						width: 23.9%;
						border-right: 0;

						@media screen and (min-width:320px) and (max-width:767px) {
							width: 22.5%;
						}
					}
				}

				.td {
					display: inline-block;
					padding: 10px 20px;
					width: 36%;
					font-size: 14px;
					border-right: 1px solid #dcdcdc;
					box-sizing: border-box;
					text-align: center;

					@media screen and (min-width:320px) and (max-width:767px) {
						padding: 10px 10px;
						display: table-cell;
					}

					&:nth-child(1) {
						width: 15%;
					}

					&:nth-child(2) {
						width: 23%;
					}

					&:nth-child(3) {
						width: 23%;
					}

					&:nth-child(4) {
						width: 15%;
					}

					&:nth-child(5) {
						width: 23.6%;
						border-right: 0;

						@media screen and (min-width:320px) and (max-width:767px) {
							width: 22.5%;
						}
					}
				}
			}
		}
	}

	.production {
		margin-bottom: 40px;

		.title {
			text-align: center;

			h4 {
				color: #666;
			}
		}

		.table {
			margin-top: 40px;

			.tr {
				border-bottom: 1px solid #dcdcdc;

				@media screen and(min-width:320px) and (max-width:767px) {
					font-size: 14px;
				}

				&:nth-child(2n + 1) {
					background-color: #F2F3F3;
				}

				&:last-child {
					border-bottom: 0;
				}

				.th {
					display: inline-block;
					padding: 15px 0;
					width: 36%;
					border-right: 1px solid #dcdcdc;
					font-weight: 800;
					text-align: center;
					background-color: #fff;
					color: #215FB0;

					&:nth-child(2) {
						width: 27.7%;

						@media screen and(min-width:320px) and (max-width:767px) {
							width: 27.4%;
						}
					}

					&:last-child {
						border-right: 0;
					}
				}

				.td {
					display: inline-block;
					padding: 15px 20px;
					width: 36.1%;
					font-weight: 800;
					box-sizing: border-box;
					text-align: center;
					border-right: 1px solid #dcdcdc;
					background-color: #F3F3F3;

					@media screen and(min-width:320px) and (max-width:767px) {
						padding: 10px;
					}

					&:nth-child(2) {
						width: 27.9%;

						@media screen and(min-width:320px) and (max-width:767px) {
							width: 27.4%;
						}
					}

					&:last-child {
						width: 36%;
						border-right: 0;
					}
				}
			}
		}
	}

	.characteristic {
		margin-bottom: 40px;

		.title {
			text-align: center;

			h4 {
				color: #666;
			}
		}

		.label {
			padding-left: 20px;
			padding-bottom: 15px;
			margin-top: 10px;
			color: #666;
			border-bottom: 1px solid #e7e7e7;

			ul {
				margin: 0 0;
				padding: 0 0;
				color: #215FB0;

				li {
					&::marker {
						color: #215FB0;
					}
				}
			}
		}

		.test {
			padding-left: 20px;
			margin-top: 10px;
			padding-bottom: 15px;
			border-bottom: 1px solid #2252AA;
			color: #666666;

			img {
				max-width: 100%;
				margin-top: 20px;
			}
		}
	}

	.title-h4 {
		color: #0064C8;
		margin: 0 0;
	}

	.mineinfo {
		margin: 0px 0 40px;

		.left {
			width: 20%;
			margin-right: 3%;
			display: inline-block;
			font-size: 14px;

			img {
				width: 100%;
				min-height: 160px;
			}

			@media screen and(min-width:320px) and(max-width:767px) {
				width: 100%;
				margin-right: 0;
			}

			.name {
				margin: 10px 0;

				span {
					&:last-child {
						margin-left: 50px;
						color: #0064C8;
					}
				}
			}

			h4 {
				margin: 0 0;
			}

			.address {
				color: #0064C8;
				margin-top: 10px;
			}
		}

		.right {
			width: 77%;
			display: inline-block;
			vertical-align: top;

			@media screen and(min-width:320px) and(max-width:767px) {
				width: 100%;
			}

			.title {
				text-align: center;
			}

			.table {
				border-top: 1px solid #dcdcdc;
				margin-top: 20px;

				.tr {
					border-bottom: 1px solid #dcdcdc;

					&:nth-child(2n-1) {
						background-color: #F2F3F3;
					}

					.td {
						display: inline-block;
						vertical-align: middle;
						width: 75%;
						border-left: 1px solid #dcdcdc;
						padding: 10px 40px;
						box-sizing: border-box;
						color: #0064C8;

						@media screen and(min-width:320px) and(max-width:767px) {
							padding: 10px;
							font-size: 14px;
						}

						&:nth-child(1) {
							width: 24.9%;
							text-align: right;
							border-left: 0;
							font-weight: 800;
						}

						&:nth-child(2) {
							color: #666666;
						}
					}
				}

				ul {
					margin: 0 0;
					padding: 0 0;

					@media screen and(min-width:320px) and(max-width:767px) {
						margin-left: 20px;
					}
				}
			}
		}
	}

	.right-info {
		width: 100%;
		margin-left: 3%;
		display: inline-block;
		vertical-align: top;

		.mine-table {
			border: 1px solid #e5e5e5;
		}
		@media screen and(min-width:320px) and(max-width:767px) {
			width: 100%;
			margin-left: 0;
			margin-top: 20px;
			.mine-table {
				border: 0;
			}
		}
	}

	.top-nav {
		width: 25%;
		display: inline-block;
		vertical-align: top;
		@media screen and(min-width:320px) and(max-width:767px) {
			width: 100%;
			border: 1px solid #D9D9D9;
		}
		.select-item {
			margin-bottom: 20px;

			@media screen and(min-width:320px) and(max-width:767px) {
				display: none;
			}

			.el-select {
				width: 47.5%;
				margin-right: 5%;

				&:last-child {
					margin-right: 0;
				}

				.el-input {
					font-size: 14px;
				}

				.el-input__inner {}
			}
		}

		.nav-item {
			font-size: 14px;
			padding: 12px 15px;
			background-color: #EBEBEB;
			cursor: pointer;
			box-sizing: border-box;
			&:hover {
				color: #fff;
				background-color: #00337D;
			}

			@media screen and(min-width:320px) and (max-width:767px) {
				width: 100%;
				display: block;
				margin: 0 auto;
				padding:10px 15px;
				border-bottom: 1px solid #D9D9D9;
				background-color: #fff;
			}
		}

		.active {
			background: #00337D;
			color: #fff;

			@media screen and(min-width:320px) and (max-width:767px) {
				border-bottom: 1px solid #00337D;
				border-radius: 5px;
			}
		}
	}

	.operationinfo {
		margin-top: 62px;
		min-height: 89vh;
		padding-top: 20px;

		.top {
			margin: 30px 0;
			display: flex;
			justify-content: space-between;

			@media screen and(min-width:320px) and(max-width:767px) {
				display: block;
			}

			.title {
				font-size: 26px;
				letter-spacing: 2px;

				@media screen and(min-width:320px) and (max-width:767px) {
					display: none;
				}
			}

			.select-item {
				display: none;

				@media screen and(min-width:320px) and (max-width:767px) {
					display: block;

					.el-select {
						display: inline-block;
						vertical-align: top;
						width: 48%;

						&:nth-child(1) {
							margin-right: 3.5%;
						}
					}
				}
			}

			.search {
				@media screen and(min-width:320px) and(max-width:767px) {
					margin-top: 20px;
				}
				input {
					border: 0;
					outline: none;
					height: 40px;
					padding: 10px;
					box-sizing: border-box;
					color: #999;
					width: 380px;
					vertical-align: middle;

					@media screen and(min-width:320px) and(max-width:767px) {
						width: 75%;
						border-top-left-radius: 5px;
						border-bottom-left-radius: 5px;
					}
				}

				.btn {
					color: #fff;
					background-color: #00337D;
					height: 40px;
					line-height: 40px;
					padding: 0 20px;
					display: inline-block;
					vertical-align: middle;
					box-sizing: border-box;

					@media screen and(min-width:320px) and(max-width:767px) {
						width: 25%;
						text-align: center;
						border-top-right-radius: 5px;
						border-bottom-right-radius: 5px;
					}
				}
			}
		}
	}
</style>
